import Grid from "@material-ui/core/Grid";
import LogoSVG from "./logo.svg";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import React from "react";
import {useStyles, theme, MetaTags} from "./App";
import Carousel from "react-material-ui-carousel";
import Project1Schiebetuer from "./projects/project1/schiebetuer.jpeg";
import Project1Haustuer1 from "./projects/project1/haustuer1.jpeg";
import Project1Rollladen1 from "./projects/project1/rollladen1.jpeg";
import Project1Terrassentuer from "./projects/project1/terrasentuer.jpeg";
import Project1Insektenschutz1 from "./projects/project1/insektenschutz1.jpeg";
import Project1TreppeBefore from "./projects/project1/treppe_before.jpeg";
import Project1Treppe1 from "./projects/project1/treppe1.jpeg";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Link from "@material-ui/core/Link";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Container from "@material-ui/core/Container";

import Tuer from "./tuer.png";

function Konfigurator({header}){
    const classes = useStyles();
    return (
        <Grid container spacing={4} className={classes.massstabOnRight} >
            {/*<Grid item xs="12" className={classes.massstab} />*/}
            <MetaTags
                title={"Konfigurator"}
                description={"borowitz FTT projekte GmbH - Konfigurator - Geschäftsführer: Robert Borowitz"}
                path={"konfigurator/"}
            />
            {header}
            <Grid item id="configurator">
                <Typography variant="h4" component="h4" color="secondary">Konfiguratoren</Typography>

                <Grid component={Paper} elevation={3} container className={classes.service}>
                  <Grid item xs={12} sm>
                    <Typography variant="h4" component="h4">T&uuml;renkonfigurator</Typography>
                    <Typography varianty="body1">
                    Ab sofort k&ouml;nnen Sie auch unseren Konfigurator verwenden, um Ihre T&uuml;r zu gestalten und ein Angebot zu erhalten. W&auml;hlen Sie zwischen unterschiedlichen Modellen, der Bauform, und vielem mehr. Sie erhalten direkt f&uuml;r Ihre Wunschkonfiguration eine Au&szlig;en- und Innenansicht. Sie haben anschlie&szlig;end die M&ouml;glichkeit, uns eine Preisanfrage zu senden, die wir versuchen schnellst m&ouml;glich zu bearbeiten.
                    </Typography>
                    <Button href='https://borowitz.kreadoor.de/?encodeddealeremail=Ym9yb3dpdHpAYm8tZnR0LXByby5kZQ==' target='_blank' className={classes.button} variant="contained" color="primary">Zum Konfigurator</Button>
                  </Grid>
                  <Grid item>
                    <img src={Tuer} loading='lazy' />
                  </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Konfigurator;
